//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
import moment from "moment";
export default {
  name: "PzOfertaSolicitudTable",
  mixins: [PzOfertaSolicitudDefault],
  data: function data() {
    return {
      date: ''
    };
  },
  created: function created() {
    this.getDate();
  },
  methods: {
    getDate: function getDate() {
      {
        this.date = moment().add(this.myForm.producto.term_type.cant_days, 'days').format(this.$t('date_format'));
      }
    }
  }
};