import "core-js/modules/es.array.map";
import "core-js/modules/es.object.values";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PzSimulatorsTemplate',
  components: {},
  inject: ['myForm'],
  provide: function provide() {
    return {
      myForm: this.myForm
    };
  },
  data: function data() {
    return {
      leyenda: []
    };
  },
  created: function created() {
    if (this.$t('forms_flujo.pz_simulators_template.approximate_value') instanceof Object) this.leyenda = Object.values(this.$t('forms_flujo.pz_simulators_template.approximate_value')).map(function (value) {
      return value;
    });else this.leyenda = {
      1: this.$t('forms_flujo.pz_simulators_template.approximate_value')
    };
  },
  methods: {
    handleBack: function handleBack() {
      return this.myForm.handleBack();
    },
    handleForward: function handleForward() {
      return this.myForm.handleSave();
    }
  }
};