export var CONFIGURATIONFront = {
  views: {
    instructions: true,
    preview: true
  },
  sharpnessThreshold: 50,
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "iniciando",
        processing: "procesando",
        scan: {
          none: "ENFOCA TU ID SOBRE LA GUÍA",
          smallDocument: "ACERCATE MÁS",
          goodDocument: "",
          capturing: "CAPTURANDO ",
          tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR"
        },
        manualCapture: {
          instruction: "Frente",
          buttonNext: "Continuar"
        }
      },
      legendsInstructions: {
        title: 'Identificación',
        subtitle: 'Captura tu identifcación',
        buttonNext: 'Continuar'
      },
      legendsPreview: {
        title: 'Identificación',
        subtitle: 'Imagen de tu identificación',
        confirmation: '¿Los datos de tu identificación son legibles?',
        buttonNext: 'Sí, continuar',
        buttonRetry: 'Volver a capturar'
      }
    }
  },
  pathDependencies: {//  imageDirectory: window.location.host
  }
};
export var CONFIGURATIONReverse = {
  views: {
    instructions: false,
    preview: true
  },
  sharpnessThreshold: 50,
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "iniciando",
        processing: "procesando",
        scan: {
          none: "ENFOCA TU ID SOBRE LA GUÍA",
          smallDocument: "ACERCATE MÁS",
          goodDocument: "",
          capturing: "CAPTURANDO ",
          tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR"
        },
        manualCapture: {
          instruction: "Reverso",
          buttonNext: "Continuar",
          tooltip: "Captura nuevamente",
          mobile: {
            instruction: "Captura el Reverso de tu identificación",
            buttonNext: "Continuar"
          },
          desktop: {
            instruction: "Sube el Reverso de tu identificación",
            title: "Reverso"
          }
        }
      },
      legendsInstructions: {
        title: 'Identificación',
        subtitle: 'Captura tu identifcación',
        buttonNext: 'Continuar'
      },
      legendsPreview: {
        title: 'Identificación',
        subtitle: 'Imagen trasera de tu identificación',
        confirmation: '¿Los datos de tu identificación son legibles?',
        buttonNext: 'Sí, continuar',
        buttonRetry: 'Volver a capturar'
      }
    }
  },
  pathDependencies: {// imageDirectory: 'ASSETS_URL'
  }
};
export var CONFIGURATIONFace = {
  views: {
    instructions: true
  },
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "Iniciando",
        processing: "procesando",
        facetec: {
          accessibilityCancelButton: "Cancelar",
          feedbackCenterFace: "Centra tu rostro",
          feedbackFaceNotFound: "Enfoca tu rostro",
          feedbackMoveAwayWeb: "Aléjate",
          feedbackMoveWebCloser: "Acércate",
          feedbackMovePhoneAway: "Aléjate",
          feedbackMovePhoneCloser: "Acércate",
          feedbackHoldSteady: "No te muevas",
          feedbackMoveWebEvenCloser: "Aún más cerca",
          instructionsHeaderReadyDesktop: "Biometría facial",
          instructionsMessageReadyDesktop: "Enfoca tu rostro en la guía y da clic en el botón para continuar",
          instructionsHeaderReadyMobile1: "Biometría facial",
          instructionsHeaderReadyMobile2: "",
          instructionsMessageReadyMobile1: "Enfoca tu rostro en la guía y",
          instructionsMessageReadyMobile2: "da clic en el botón para continuar",
          actionImReady: "Continuar",
          resultFacescanUploadMessage: "procesando",
          retryHeader: "Inténtalo nuevamente",
          retrySubheaderMessage: "Necesitamos una imagen clara",
          retryYourImageLabel: "Tu foto",
          retryIdealImageLabel: "Pose ideal",
          retryInstructionMessage1: "Sin brillo o iluminación extrema",
          retryInstructionMessage2: "Expresión neutral, sin sonreír",
          retryInstructionMessage3: "Demasiado borroso, limpia tu cámara",
          presessionFrameYourFace: "Enfoca tu rostro en la guía",
          presessionLookStraightAhead: "Mira al frente",
          presessionHoldSteady3: "No te muevas por: 3",
          presessionHoldSteady2: "No te muevas por: 2",
          presessionHoldSteady1: "No te muevas por: 1",
          presessionEyesStraightAhead: "Mira al frente",
          presessionRemoveDarkGlasses: "Quítate los lentes de sol",
          presessionNeutralExpression: "Expresión neutral, sin sonreír",
          presessionConditionsTooBright: "Entorno com demasiada luz",
          presessionBrightenYourEnvironment: "Entorno con poca luz",
          actionTryAgain: "Aceptar",
          cameraPermissionHeader: "Permiso de cámara o micrófono desactivado",
          cameraPermissionMessage: "Por favor revisa la configuración de tu sistema operativo y los ajustes del navegador.",
          cameraPermissionLaunchSettings: "Aceptar",
          initializingCamera: "iniciando",
          initializingCameraStillLoading: "iniciando...",
          resultSuccessMessage: "Validación correcta",
          enterFullscreenHeader: "Prueba de vida",
          enterFullscreenMessage: "Antes de comenzar da clic en el botón de abajo para abrir en pantalla completa",
          enterFullscreenAction: "Continuar"
        }
      },
      legendsInstructions: {
        title: 'Prueba de vida',
        subtitle: 'Enfoca tu rostro en la guía',
        buttonNext: 'Continuar',
        instructions: 'Recuerda no hacer uso de lentes de sol, gorras u otros elementos que dificulten la identificación de tu rostro.'
      }
    }
  },
  pathDependencies: {// imageDirectory: 'ASSETS_URL'
  }
};
export var CREDENTIALSAcuant = {
  passiveUsername: 'acuantEUUser@naat.com',
  passivePassword: 'Q^59zWJzZ^jZrw^q',
  passiveSubscriptionId: 'c681321c-2728-4e8a-a3df-a85ba8a11748',
  acasEndpoint: 'https://eu.acas.acuant.net',
  livenessEndpoint: 'https://eu.passlive.acuant.net',
  assureidEndpoint: 'https://eu.assureid.acuant.net'
};
export var CREDENTIALSFace = {
  deviceKeyIdentifier: "dAaa7DjCJH7f4zuLwJFFlSjgAXL6k8q2",
  baseURL: "https://facetec-preprod.firmaautografa.com",
  publicFaceScanEncryptionKey: "-----BEGIN PUBLIC KEY-----\n" + "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" + "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" + "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" + "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" + "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" + "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" + "8QIDAQAB\n" + "-----END PUBLIC KEY——",
  productionKeyText: {
    domains: "",
    expiryDate: "2023-06-10",
    key: "00304602210084ff3bf9597db58c0ed49b692fbd02c6460647e4c4cfca85f0be4ed0150c70e8022100ff44295553aa02db0fc7d146fe6130b9bc8bdcdb05df3c9ae35a32136b18000d"
  }
};